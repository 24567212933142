<template>
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <Breadcrumb :datas='breadcrumb_data' />
      <!--begin::Entry-->
      <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid">
          <!--begin::Card-->
          <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
              <div class="card-title">
                <h3 class="card-label">
                  {{ $t('branch_dash.merchant_deposits') }}
                </h3>
              </div>
              <div class="card-toolbar">
              </div>
            </div>
            <div class="card-body">
                <Datatable
                    ref="datatable"
                    :url="datatableUrl"
                    :dataUrl="datatableDataUrl"
                    @data="loadDatatableData($event)"
                >
                    <!-- <template v-slot:head>
                        <tr>
                          <th>#</th>
                          <th>{{ $t('service.commons.institution') }}</th>
                          <th>{{ $t('service.commons.employee') }}</th>
                          <th>{{ $t('service.commons.fullname') }}</th>
                          <th>{{ $t('service.commons.currency') }}</th>
                          <th>{{ $t('service.commons.amount') }}</th>
                          <th>{{ $t('service.commons.commission') }}</th>
                        </tr>
                    </template> -->
                    <template v-slot:body>
                      <tr v-for="(item, index) in datatableData" :key="'merchant-deposit-' + index">
                        <th scope="row">{{ item.id }}</th>
                        <td>
                            {{ item.institution.name }}
                        </td>
                        <td>
                            {{ item.employee }}
                        </td>
                        <td>{{ item.user.name }} {{ item.user.surname }}</td>
                        <td>{{ item.currency_name }}</td>
                        <td>{{ item.amount }}</td>
                        <td v-html="item.fee"></td>
                        <td>{{ item.fee_rate }}</td>
                        <td>{{ item.order_id }}</td>
                        <td>{{ item.deposit_id }}</td>
                        <td v-html="item.created_at"></td>
                        <td>
                          <div class="d-flex justify-content-end flex-shrink-0">
                            <button @click.prevent="downloadPDF(item.id)" class="btn btn-icon btn-clean btn-bg-light btn-sm" :title="$t('commons.download')">
                              <i class="far fa-file fs-4"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </template>
                </Datatable>
            </div>
          </div>
          <!--end::Card-->
        </div>
        <!--end::Container-->
      </div>
      <!--end::Entry-->
    </div>
  </template>
  
  <script>
  import {mapState} from 'vuex';
  import Breadcrumb from '@/components/layout/Breadcrumb.vue'
  import 'bootstrap-daterangepicker';
  import Datatable from "@/components/datatable/Datatable.vue";
  import api_old from "@/api/index_old";
  
  export default {
    name: 'DepositTransactions',
    data() {
      return {
        isLoading:true,
        datatableUrl: "",
        datatableDataUrl: "",
        datatableData: [],
      }
    },
    components: {
      Breadcrumb,
      Datatable,
    },
    computed: {
      ...mapState({
        permissions: (state) => state.customer.permissions,
        lang: (state) => state.global.lang,
      }),
      breadcrumb_data() {
        return {
          title: this.$t('branch_dash.title'),
          subtitle: [
            this.$t('branch_dash.merchant_deposits')
          ]
        }
      }
    },
    methods: {
      loadDatatableData(data) {
        this.$set(this, "datatableData", data);
      },
      checkPermission(permission) {
        return this.permissions?.some(val => val.name == permission);
      },
      statusClass(status) {
          switch (status) {
              case 10:
                  return 'badge-warning';
              case 20:
              case 70:
                  return 'badge-light';
              case 30:
                  return 'badge-success';
              case 40:
              case 50:
              case 60:
                  return 'badge-danger';
              default:
                  return '';
          }
      },
      statusLabel(status) {
          if(status) {
              return this.$t(`f_management.statuses.${status}`)
          }
          return '-'
      },
      initDatatable() {
        this.$refs.datatable.loadData();
      },
      downloadPDF(id) {
        let data = { url: `${api_old.transactions}/deposit/${id}/pdf`, label: `merchant-deposit-${id}.pdf` };
        this.$store.dispatch('global/GET_PDF', data);
      },
    },
    created() {
      if(this.permissions?.length > 0 && !this.checkPermission('crm.merchant.index')) {
        this.$router.push('/no-permission')
      }
      this.datatableUrl = api_old.merchant_deposits_datatable;
      this.datatableDataUrl = api_old.merchant_deposits_datatable_data;
        
    },
  }
  </script>
  <style lang="scss" scoped>
    .truncateText {
      min-width: 150px;
      overflow: hidden;
      position: relative;
      margin: 0 5px 0 5px;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
  </style>
  